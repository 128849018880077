import {
  mapGetters, mapActions, mapMutations, mapState,
} from 'vuex';

import cloneDeep from 'lodash.clonedeep';

import Api from '@/services/api/Api';

const helperMixins = {
  data() {
    return {
      fetchingVideos: [],
    };
  },
  computed: {
    ...mapGetters('userData', [
      'isFreeUser',
    ]),
    ...mapGetters('canvasElements', ['getProjectBrandId']),
    ...mapState([
      'isTemplate',
    ]),
    ...mapState('canvasElements', [
      'elementsList',
      'cachedVideos',
    ]),
  },
  methods: {
    ...mapMutations([
      'pushCustomFonts',
    ]),
    ...mapMutations('canvasElements', [
      'updateCachedVideos',
    ]),
    isEmpty(param) {
      return (param === undefined) || (param === null) || (param === '');
    },
    checkDuplicateNotif(message = '') {
      const notifications = Array.from(document.getElementsByClassName('notification-content'));
      let duplicateContent = false;
      if (notifications.length) {
        notifications.forEach((notification) => {
          if (notification.textContent === message) {
            duplicateContent = true;
          }
        });
      }
      return duplicateContent;
    },
    alertSuccess(title = '', message = '', duration = -1, group = '') {
      setTimeout(() => {
        if (this.checkDuplicateNotif(message)) return;
        this.$notify({
          group: group || 'BottomRightNotif',
          title: title || 'Hey there!',
          type: 'success',
          text: (group === 'TopCenterNotif' ? '<i class="icon icon-tick"></i>' : '') + message,
          duration,
        });
      }, 100);
    },
    alertError(title = '', message = '', duration = -1, group = '', showReportError = false) {
      // to avoid duplicate notifications
      setTimeout(() => {
        if (this.checkDuplicateNotif(message)) return;
        this.$notify({
          group: group || 'BottomRightNotif',
          title: title || 'Hey there!',
          type: 'error',
          text: (group === 'TopCenterNotif' ? '<i class="icon icon-close"></i>' : '') + message,
          duration,
          data: {
            showReportError,
          },
        });
      }, 100);
    },
    alertWarn(title = '', message = '', duration = -1, group = '') {
      if (this.checkDuplicateNotif(message)) return;
      this.$notify({
        group: group || 'BottomRightNotif',
        title: title || 'Hey there!',
        type: 'warn',
        text: (group === 'TopCenterNotif' ? '<i class="icon icon-alert"></i>' : '') + message,
        duration,
      });
    },
    alertLoading(type = '', group = '', clear = false) {
      // need to move to another group since "TopCenterNotif" group not allowing conditional class binding while for loader notif need to modify it's positioning.
      this.$notify({
        group: group || 'LoadingTopCenterNotif',
        type: 'loading',
        text: `Loading ${type}...`,
        duration: 180000,
        clear,
        ignoreDuplicates: true,
        data: {
          hasPreloader: true,
        },
      });
    },
    alertUpload(type = '', message = '', duration = -1, showTryAgainButton = false, group = '') {
      this.$notify({
        group: group || 'UploadBottomLeftNotif',
        type,
        text: message,
        duration,
        data: {
          hasTryAgain: !!showTryAgainButton,
        },
      });
    },
    initCustomFonts(userId = '') {
      // console.log('initCustomFonts', userId, this.isFreeUser);
      // console.log('init custom fonts', this.userId, this.name);

      if (!userId) return;
      const customFontUrl = `${process.env.VUE_APP_OFFEO_API}/v2/custom-fonts/font-`;
      // const customFontUrl = 'https://api.offeo.com/v1/custom-fonts/font-';
      const customFontCss = document.createElement('link');
      const attrId = `custom-fonts-css-${userId}`;

      if (document.getElementById(attrId) !== null) return; // skip if already attached

      customFontCss.setAttribute('id', attrId);
      customFontCss.setAttribute('rel', 'stylesheet');
      customFontCss.setAttribute('href', `${customFontUrl}${userId}.css`);
      document.head.appendChild(customFontCss);

      // console.log('initCustomFonts - attached');
      Api.customFonts(`font-${userId}.json`)
        .then((response) => {
          // console.log('Api.customFonts', response.data);
          const fonts = response.data;
          Object.keys(fonts).forEach((key) => {
            this.pushCustomFonts(fonts[key]);
          });
        }).catch((error) => {
          console.log(error);
        });
    },
    initCustomProjectFonts(projectId = '', isTemplate = false) {
      let customFontUrl = `${process.env.VUE_APP_OFFEO_API}/v1/custom-project-fonts`;

      if (!projectId) return;

      if (isTemplate) {
        customFontUrl += '/template-';
      } else {
        customFontUrl += '/project-';
      }

      const customFontCss = document.createElement('link');
      const attrId = `custom-project-fonts-css-${projectId}`;

      if (document.getElementById(attrId) !== null) return; // skip if already attached

      customFontCss.setAttribute('id', attrId);
      customFontCss.setAttribute('rel', 'stylesheet');
      customFontCss.setAttribute('href', `${customFontUrl}${projectId}.css`);
      document.head.appendChild(customFontCss);

      // console.log('initCustomFonts - attached');

      const customProjectFontJson = isTemplate
        ? `template-${projectId}.json`
        : `project-${projectId}.json`;

      Api.customProjectFonts(customProjectFontJson)
        .then((response) => {
          // console.log('Api.customFonts', response.data);
          const fonts = response.data;
          Object.keys(fonts).forEach((key) => {
            this.pushCustomFonts(fonts[key]);
          });
        }).catch((error) => {
          console.log(error);
        });
    },
    initTeamCustomFonts(teamId, teamBrandIds) {
      if (!teamId || !teamBrandIds.length) return;

      const apiUrl = process.env.VUE_APP_OFFEO_API;
      const cdnUrl = process.env.VUE_APP_CDN_ASSETS;

      const allStyleCss = document.createElement('link');

      allStyleCss.setAttribute('rel', 'stylesheet');
      allStyleCss.setAttribute('href', `${cdnUrl}/fonts/all-styles.css`);
      document.head.appendChild(allStyleCss);

      let customFontCss = null;
      let customFontUrl = '';

      // get all custom font files in all brand inside team brand font & inject to html document
      teamBrandIds.forEach((id) => {
        customFontCss = document.createElement('link');
        customFontUrl = `${apiUrl}/v2/custom-team-fonts?team_id=${teamId}&brand_id=${id}&type=css`;

        customFontCss.setAttribute('id', `custom-fonts-css-${id}`);
        customFontCss.setAttribute('rel', 'stylesheet');
        customFontCss.setAttribute('href', customFontUrl);
        document.head.appendChild(customFontCss);
      });
    },
    saveVideoElementsToBlob() {
      // cache video elements
      const elements = cloneDeep(this.elementsList.byId);
      // console.log('saveVideoElementsToBlob', elements);

      let delayInMs = 0;

      // eslint-disable-next-line
      Object.values(elements).map((element) => {
        if (element.type !== 'videos' || !element.show) return;
        const { url } = element.data;
        const newUrl = url.split('#t=')[0];

        setTimeout(() => {
          this.requestVideo(newUrl);
        }, delayInMs);
        delayInMs += 200;
      });
    },
    requestVideo(url, tries = 0) {
      if (tries > 5) return;
      // console.log('requestVideo', url, tries);
      if (this.fetchingVideos.includes(url) || typeof this.cachedVideos[url] !== 'undefined') return;

      const req = new XMLHttpRequest();
      req.open('GET', url, true);
      req.responseType = 'blob';


      // ?future add percent progress in video preloaders
      // req.onprogress = (event) => {
      //   // console.log('onprogress', event);
      //   const { loaded, total, currentTarget } = event;
      //   const percentage = (loaded / total) * 100;
      //   console.log('blob video:', `${percentage.toFixed(0)}%`, `readyState: ${currentTarget.readyState}`, url);
      // };

      req.onload = (event) => {
        const { target } = event;
        // console.log('url!!!!', target);
        // Onload is triggered even on 404
        // so we need to check the status code
        if (target.status === 200) {
          const videoBlob = target.response;
          const blob = URL.createObjectURL(videoBlob); // IE10+
          // Video is now downloaded
          // and we can set it as source on the video element
          // console.log('Video cached', url, blob);
          this.$nextTick(() => {
            this.updateCachedVideos({
              videoUrl: url,
              data: {
                url: blob,
                isVideoLoaded: false,
              },
            });
            const fetchingIndex = this.fetchingVideos.indexOf(url);
            if (fetchingIndex !== -1) this.fetchingVideos.splice(fetchingIndex, 1);
          });
        }
      };

      req.onerror = () => {
        const fetchingIndex = this.fetchingVideos.indexOf(url);
        if (fetchingIndex !== -1) this.fetchingVideos.splice(fetchingIndex, 1);
        setTimeout(() => {
          this.requestVideo(url, tries + 1);
        }, 200);
      };

      req.send();
    },
    trackData(key = '', value = '') {
      if (!key || !value) return;

      /**
       * key: db.table_name
       * value: db.table.element.PRI_id
       */

      Api.dataTracking({
        data_key: key,
        data_value: value,
      })
        .then(() => {
          console.log('');
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};

export default helperMixins;
