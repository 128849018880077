<template lang="pug">
.subscribe-page
  Subscribe(
    v-if="!isPayingUser && showUpgradeSubscription"
    :showUpgradeNextTimeButton="showUpgradeNextTimeButton"
    @close-subscribe="closeSubscribeDialog"
  )
</template>

<script>
import { mapMutations, mapGetters, mapState } from 'vuex';
import { isWindowEventListenerAllowed } from '@/assets/scripts/utilities';
import { iframeParentURL } from '@/assets/scripts/variables';

import Subscribe from '@/components/ProjectCanvas/PageOverlay/Subscribe.vue';

export default {
  components: {
    Subscribe,
  },
  data() {
    return {
      showUpgradeNextTimeButton: true,
    };
  },
  computed: {
    ...mapState(['showUpgradeSubscription']),
    ...mapGetters('userData', ['isPayingUser']),
  },
  beforeMount() {
    document.body.classList.add('is-transparent');
    this.setShowUpgradeSubscription(true);
  },
  mounted() {
    window.addEventListener('message', (event) => {
      // IMPORTANT: check the origin of the data!
      // console.log('event origin login', event.origin, event.data, isWindowEventListenerAllowed(event))
      if (isWindowEventListenerAllowed(event)) {
        // The data was sent from your site.
        // Data sent with postMessage is stored in event.data:
        // console.log('subscribe', event.origin, event.data);
        if (event.data.subscribe) {
          this.setShowUpgradeSubscription(true);
        }
      }
    });
  },
  methods: {
    ...mapMutations(['setShowUpgradeSubscription']),
    closeSubscribeDialog() {
      this.setShowUpgradeSubscription(false);
      window.parent.postMessage({ close: true }, iframeParentURL());
    },
  },
};
</script>

<style lang="scss">
@import '~normalize.css';
@import '~@/assets/icomoon/style.scss';

body.is-transparent {
  background: transparent;
}

.visuallyhidden,
.vh {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

body {
  background: #fff;
  font-weight: 500;
  scroll-behavior: smooth;
  font-family: $baseFont;

  * {
    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(230, 230, 230, 0.5);
    }

    &::-webkit-scrollbar-thumb {
      background: $timelineGrey;
      transition: background 0.25s ease-in-out;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: darken($timelineGrey, 5%);
    }
  }

  .crisp-client * {
    visibility: hidden !important;
    opacity: 0;
    pointer-events: none;
  }
}

button {
  border: 0;
  cursor: pointer;
  background: transparent;

  &:active,
  &:hover,
  &:focus {
    outline: 0;
    background: inherit;
  }
}

#app {
  font-family: $baseFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}
</style>
